import React, { useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClientTestimonials from "./ClientTestimonials";
import ClientsLandlordRep from "./ClientsLandlordRep";
import PropertiesCardsSection from "./PropertiesCardsSection";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { getDocData } from "../Firebase.utils/Firebase.utils";
import { Link } from "react-router-dom";

function LandlordRep() {
  const [landlordRepData, setLandlordRepData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchLandlordRepData = async () => {
      const data = await getDocData("website", "landlordRep");
      setLandlordRepData(data);
      setCards(data.cards);
      setIsLoading(false);
    };
    fetchLandlordRepData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const landloredRepCards = [
    {
      title: "Tenant Identification",
      description:
        "We assist in identifying potential tenants for your property, utilizing our extensive network and market expertise to attract quality occupants that align with your property's objectives.",
    },
    {
      title: "Lease Negotiation",
      description:
        "Our team negotiates lease terms with prospective tenants to secure favorable agreements on your behalf, maximizing rental income and minimizing vacancies.",
    },
    {
      title: "Lease Renewal and Restructuring",
      description:
        " We manage lease renewals and restructuring agreements to ensure continued tenant satisfaction and maximize property value.",
    },
    {
      title: "Market Analysis",
      description:
        "Utilizing comprehensive market analsysis, we provide insights into current market trends and competitor analysis to inform strategic decision-making. ",
    },
    {
      title: "Transaction Management",
      description:
        "From document preparation to finalizing contracts, we oversee the entire leasing process to ensure efficiency and compliance with legal requirements.",
    },
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-leechiuBlue">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Landlord Representation | Leechiu Property Consultants</title>
        <meta
          name="description"
          content={
            " Our leasing agents secure tenants for office buildings and commercial space. We help our clients achieve maximum occupancy and rent for their projects. Our portfolio includes office buildings, office floors, fitted space and retail buildings."
          }
        />
      </Helmet>
      <div
        className="bg-leechiuBlue h-[500px]"
        style={{
          backgroundImage:
            "url('https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2FlandlordRep%2Fbusinessman-communication-company-meeting-concept-2023-11-27-04-58-21-utc.jpg?alt=media&token=4c0a638f-2c76-4a13-bbba-66333c4431ba')",

          backgroundSize: "cover",
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-leechiuBlue bg-opacity-60 h-full w-full flex items-center justify-center">
          <div className="font-serif text-4xl text-white font-semibold px-4">
            Landlord Representation
          </div>
        </div>
      </div>
      <div className=" flex items-center justify-center flex-wrap">
        <div className="">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2FlandlordRep%2Flandlord-rep-lpc%202.jpg?alt=media&token=c17d7775-03d4-48fd-8c77-872c3ec546ab"
            alt=""
            className="h-[500px] w-[500px] object-cover"
            style={{
            
              objectFit: "contain",
            }}
          />
        </div>
        <div className="w-[600px] bg-white text-left px-16 py-8">
          <div className="font-semibold">
            <div className="font-serif text-4xl text-leechiuBlue">
              Exclusive
            </div>
            <div className="font-serif text-4xl text-leechiuOrange">
              Leasing Partner
            </div>
          </div>
          <div className="text-leechiuBlue mt-8">
            {landlordRepData.pageDescription}
          </div>
        </div>
      </div>
      <div>
        <div className="text-white mt-8">
          <div className="font-serif text-4xl font-semibold">
            {"Our "}
            <span className="text-leechiuOrange">Services</span>
          </div>
          <div className="max-w-6xl m-auto mt-4 max-sm:text-left max-sm:px-8 max-sm:text-lg">
            {landlordRepData.solutionsDescription}
          </div>
        </div>
        <div className="flex items-center gap-4 p-8 flex-wrap justify-center">
          {cards.map((card, index) => {
            return (
              <div
                className="h-32
              max-w-[500px] bg-white  rounded-xl text-sm font-sans text-start flex items-center justify-center p-4 gap-1"
                key={index}
              >
                <CheckCircleOutlineIcon />
                <div className="text-leechiuBlue">
                  <div className="font-semibold text-leechiuBlue text-xs sm:text-sm">
                    {card.title} : {""}
                    <span className="text-xs sm:text-sm font-normal">
                      {card.description}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className=" xl:h-[500px] h-full w-full flex items-center justify-center xl:relative ">
        <div className="hidden xl:block xl:h-[400px] w-full bg-white bg-opacity-60"></div>
        <div className=" xl:absolute top-0 left-0 w-full h-full flex items-center justify-center flex-wrap">
          <div className="h-full bg-leechiuBlue text-white flex items-center justify-center ">
            <div className="max-w-[600px] font-normal text-lg p-8 text-left">
              {landlordRepData.photoCardDescription}

              <div className="border-2 mt-8 w-36 text-xs px-4 py-2 text-center">
                <Link to="/contactUs">Contact Us</Link>
              </div>
            </div>
          </div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2FlandlordRep%2Flandlord%20rep-lpc%201.jpg?alt=media&token=3efc5036-031a-4a0e-b01c-d2f4ce88b0fc"
            alt=""
            className="h-[500px] w-[500px] object-cover"
          />
        </div>
      </div>
      <ClientTestimonials />
      <ClientsLandlordRep />
      <div className="mx-8 overflow-hidden p-8">
        <div className="font-serif text-3xl text-white text-left mb-8 font-semibold">
          Featured <span className="text-leechiuOrange">Properties</span>
        </div>
        <PropertiesCardsSection />
      </div>
    </div>
  );
}

export default LandlordRep;
