import React from "react";
import { Link } from "react-router-dom";

function Partners() {
  const partners = [
    {
      name: "uli",
      link: "https://uli.org/",
      logo: "https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2Faffiliates%2Fulilogo.png?alt=media&token=209e5b88-81d5-469e-af62-3c418f152fc7",
    },
    {
      name: "ibpap",
      link: "https://www.ibpap.org/",
      logo: "https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2Faffiliates%2FIBPAP_logo.png?alt=media&token=b177fb25-ab09-4bf6-9dd2-1e15de322a38",
    },
    {
      name: "eccp",
      link: "https://www.eccp.com/",
      logo: "https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2Faffiliates%2FECCP-Logo-Full-Colour.png?alt=media&token=b16f2d9d-3f63-4dc7-80b4-072df2ea1342",
    },

    {
      name: "nordcham",
      link: "https://nordcham.com.ph/",
      logo: "https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2Faffiliates%2FNordcham%20Logo.png?alt=media&token=4b108b57-a2e5-48d8-ac33-d823d8e55b52",
    },

    {
      name: "amcham",
      link: "https://amchamphilippines.com/",
      logo: "https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2Faffiliates%2FAmcham.png?alt=media&token=e000603e-3c63-495f-ae60-4c2e8166a5d9",
    },

    {
      name: "himap",
      link: "https://himap.ph/",
      logo: "https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2Faffiliates%2FHIMAP-logo.png?alt=media&token=3efda251-f2c2-4abe-babf-168518ddc6f7",
    },

    {
      name: "bccp",
      link: "https://britcham.org.ph/",
      logo: "https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2Faffiliates%2Fbritish.png?alt=media&token=4fbf8aca-b07f-44dc-839d-47c8f2123e33",
    },
  ];

  return (
    <div>
      <div>
        <div className="flex flex-wrap justify-center max-w-5xl m-auto gap-4">
          {partners.map((partner) => (
            <div className="m-4" key={partner.name}>
              <Link to={partner.link} target="_blank">
                <img src={partner.logo} alt={partner.name} className="h-12 " />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Partners;
