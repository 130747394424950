import React from "react";
import PropertySearchBar from "./PropertySearchBar";
import LaurelBand from "./LaurelBand";
import HeroInsights from "./HeroInsights";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import HeroCarousel from "./HeroCarousel";

function Hero({ pageData }) {
  return (
    <div
      className="bg-[url('https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2Fo-buildings_57223990.jpg?alt=media&token=9d4eaf01-aae3-4ff1-9f3a-520419c36e4e')]
    h-[700px] lg:h-[600px] bg-cover bg-center bg-no-repeat 
     "
    >
      <div className="h-full bg-blue-900 bg-opacity-60 flex flex-col items-center justify-center text-white text-5xl relative">
        <div className="overflow-hidden">
          <motion.div
            className={`font-serif font-medium mb-8 px-4`}
            initial={{ y: 200 }}
            animate={{ y: 0 }}
            transition={{
              duration: 1,
              delay: 0.5,
            }}
            ease="easeInOut"
          >
            {/* Every Deal Matters. */}
            Our Insights, <span className="">Your Success</span>
          </motion.div>
        </div>
        <motion.div
          className=""
          initial={{ x: "-300%" }}
          animate={{ x: 0 }}
          transition={{ duration: 0.5, delay: 1, ease: "easeInOut" }}
        >
          <HeroCarousel />
        </motion.div>

        {/* <Link
          to="/propertyFinder"
          className="bg-leechiuOrange text-xs text-left rounded-lg p-4 ml-4 md:hidden"
        >
          Search for Properties
        </Link> */}
        <div className="absolute bottom-0 w-full">
          <LaurelBand />
        </div>
      </div>
    </div>
  );
}

export default Hero;
