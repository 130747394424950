import React, { useEffect } from "react";
import { getAllDocsSnap } from "../Firebase.utils/Firebase.utils";
import NewsCard from "./NewsCard";
import BlogCard from "./BlogCard";

export default function Blogs() {
  const [activeButton, setActiveButton] = React.useState("View All");
  const activeStyle = "w-fit px-4 py-2 bg-leechiuBlue text-white";
  const inactiveStyle = "w-fit px-4 py-2 bg-white text-black";
  const [searchTerm, setSearchTerm] = React.useState("");
  const [articles, setArticles] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const handleViewAll = () => {};
  const handleRecently = () => {};

  useEffect(() => {
    const fetchAllArticles = async () => {
      await getAllDocsSnap("blogs", (data) => {
        //filter data status to be published
        data = data.filter((article) => article.status === "published");

        //arrange by date
        data.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });

        setArticles(data);
        setIsLoading(false);
      });
    };
    fetchAllArticles();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="w-full h-[500px] bg-[url('https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2Fo-buildings_57223990.jpg?alt=media&token=9d4eaf01-aae3-4ff1-9f3a-520419c36e4e')] bg-no-repeat bg-cover bg-center">
        <div className="w-full h-full bg-leechiuBlue flex items-center bg-opacity-70">
          <div className="font-serif text-5xl font-bold text-white capitalize pl-4 max-w-[1440px] mx-auto w-full text-left">
            Blogs
          </div>
        </div>
      </div>

      <div>
        <div className="flex items-center justify-between max-w-6xl m-auto mt-8 flex-wrap max-sm:ml-4 gap-4">
          <div className="flex items-center justify-center">
            <button
              className={
                activeButton === "View All" ? activeStyle : inactiveStyle
              }
              style={{
                borderRadius: "10px 0 0 10px",
                border: "1px solid #000",
              }}
              onClick={handleViewAll}
            >
              View All
            </button>
            <button
              className={
                activeButton === "Recently" ? activeStyle : inactiveStyle
              }
              style={{
                borderRadius: "0 10px 10px 0",
                border: "1px solid #000",
              }}
              onClick={handleRecently}
            >
              Recently
            </button>
          </div>
          <div className="flex items-center justify-center gap-4 ">
            <input
              type="text"
              placeholder="Search by keyword"
              className="min-w-60 px-4 py-2"
              style={{
                borderRadius: "10px",
                border: "1px solid #000",
              }}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button
              className="px-4 py-2 w-fit "
              style={{
                borderRadius: "10px",
                border: "1px solid #000",
              }}
            >
              Filters
            </button>
          </div>
        </div>
      </div>

      <div className="bg-leechiuBlue p-8 mt-8">
        <div className="max-w-[1440px] mx-auto w-full flex items-center gap-4 flex-wrap justify-center">
          {articles.map((article, index) => (
            <div>
              <BlogCard article={article} key={index} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
