"use client";
import React from "react";
import { motion } from "framer-motion";
import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const Cards = ({ heading, link, description, imgSrc }) => {
  const navigate = useNavigate();

  const handleNavigate = (link) => {
    navigate(link);
  };

  return (
    <motion.div
      transition={{
        staggerChildren: 0.035,
      }}
      whileHover="hover"
      className="w-[300px] h-64 bg-slate-300 overflow-hidden cursor-pointer group relative"
      onClick={() => handleNavigate(link)}
    >
      <div
        className="absolute inset-0 group-hover:scale-110 transition-all duration-500"
        style={{
          backgroundImage: `url(${imgSrc})`,
          backgroundSize: "cover",
        }}
      />
      <div
        className="
      bg-white bg-opacity-70
      p-4 relative z-2 h-full text-slate-300 
      group-hover:bg-leechiuOrange
      group-hover:bg-opacity-60 transition-colors duration-500 flex flex-col justify-between"
      ></div>
      <div>
        <div
          className="font-serif font-semibold text-xl p-4
        absolute bottom-20 text-center w-full z-2 leechiu-blue
        group-hover:text-white transition-colors duration-500
        "
        >
          {heading}
        </div>
        <div
          className="absolute bottom-4 text-center w-full text-sm leechiu-blue poppins-regular z-2
        group-hover:text-white transition-colors duration-500
        "
        >
          <div className="flex items-center justify-center">
            <div> Learn more</div>
            <div>
              <FiArrowRight
                className="text-2xl group-hover:-rotate-45 
              group-hover:text-white
              transition-transform duration-500 ml-auto leechiu-blue"
              />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
function ServicesCards() {
  const services = [
    {
      title: "Tenant Representation",
      description: "We help you find the best location for your business.",
      link: "/tenant-representation",
      image:
        "https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2FservicesCards%2Fbusinessman-in-formal-clothes-is-with-black-man-ou-2023-11-27-04-54-14-utc.JPG?alt=media&token=c9b14f32-63ac-422a-9fc4-90c46ddfd2a5",
    },

    {
      title: "Landlord Representation",
      description: "We help you find the best tenants for your property.",
      link: "/landlord-representation",
      image:
        "https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2FcontactUs%2Flandlord-rep-lpc%202.jpg?alt=media&token=6ecbcf8e-1353-4ca2-9328-89fdb5571b9a",
    },
    {
      title: "Investment Sales",
      description: "We help you find the best investment opportunities.",
      link: "/investment-sales",
      image:
        "https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2FcontactUs%2FIS-LPC%20photo.jpg?alt=media&token=d1459b5b-6088-4917-8712-3a3d7514c36a",
    },
    {
      title: "Research and Consultancy",
      description: "We help you make informed decisions.",
      link: "/research-consultancy",
      image:
        "https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2FcontactUs%2Ffinance-manager-meeting-discussing-company-growth-2023-11-27-05-07-55-utc.JPG?alt=media&token=df672523-333e-4c3f-8f52-7d4513264eb0",
    },
    {
      title: "Valuation and Property Appraisal",
      description: "We help you determine the value of your property.",
      link: "/valuation-property-appraisal",
      image:
        "https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2FcontactUs%2Fshot-of-a-group-of-businesspeople-listening-to-a-p-2023-11-27-05-15-43-utc.jpg?alt=media&token=a424787b-52cb-49db-9e39-55ada30ddd3e",
    },
    {
      title: "Residential Sales and Leasing",
      description: "We help you find the best home for your family.",
      link: "/residential-sales-leasing",
      image:
        "https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2FcontactUs%2Fdad-daughter-and-mom-having-fun-outdoors-2023-11-27-05-17-29-utc.jpg?alt=media&token=182d7462-85c6-4f47-8e38-f85f22e76238",
    },
    {
      title: "Hotel Tourism, and Leisure",
      description: "We help you find the best vacation spot.",
      link: "/hotels-tourism-leisure",
      image:
        "https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2FcontactUs%2Fel-nido-palawan-philippines-tropical-scenery-of-2023-11-27-05-36-20-utc.jpg?alt=media&token=aca22f53-3015-4a20-805e-cdea8483a579",
    },
  ];

  return (
    <div className="flex items-center gap-4 flex-wrap shrink-0 justify-center">
      {services.map((service, index) => {
        return (
          <Cards
            key={index}
            heading={service.title}
            description={service.description}
            imgSrc={service.image}
            link={service.link}
          />
        );
      })}
    </div>
  );
}

export default ServicesCards;
