import React from "react";
import PropertiesForSaleCardsContainer from "./PropertiesForSaleCardsContainer";
import PropertiesForLeaseCardsContainer from "./PropertiesForLeaseCardsContainer";
import { Helmet } from "react-helmet";

function Offices() {
  const [searchTerm, setSearchTerm] = React.useState("");

  const bgImage =
    "https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2Fo-buildings_57223990.jpg?alt=media&token=9d4eaf01-aae3-4ff1-9f3a-520419c36e4e";

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Commercial Property for Lease | Office Space | Leechiu Property
          Consultants
        </title>
        <meta
          name="description"
          content={
            "Searching for the perfect office space for your business? Leechiu Property Consultants offers premium commercial properties for lease, including prime office locations in major cities across the Philippines."
          }
        />
      </Helmet>
      <div
        className="w-full h-[500px]"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-leechiuBlue  bg-opacity-60 h-full w-full">
          <div className="h-full w-full  flex flex-col items-center justify-center gap-2 max-w-[1440px] mx-auto">
            <div className="pl-12 sm:pl-32 font-serif text-4xl text-white w-full text-left font-semibold">
              Office Spaces for Lease
            </div>
            <div className="pl-12 sm:pl-32 text-white text-left text-lg font-semibold">
              Search for office spaces for lease across all major cities and
              business districts in the Philippines. Let us guide you to your
              next office space.
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between max-w-6xl m-auto mt-8 flex-wrap max-sm:ml-4 gap-4">
        <div className="flex items-center justify-center">
          <button
            className="w-fit px-4 py-2 bg-leechiuBlue text-white"
            style={{
              borderRadius: "10px 0 0 10px",
              border: "1px solid #000",
            }}
          >
            View All
          </button>
          <button
            className="w-fit px-4 py-2 "
            style={{
              borderRadius: "0 10px 10px 0",
              border: "1px solid #000",
            }}
          >
            Most Recent
          </button>
        </div>
        <div className="flex items-center justify-center gap-4 ">
          <input
            type="text"
            placeholder="Search for properties"
            className="min-w-60 px-4 py-2"
            style={{
              borderRadius: "10px",
              border: "1px solid #000",
            }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            className="px-4 py-2 w-fit "
            style={{
              borderRadius: "10px",
              border: "1px solid #000",
            }}
          >
            Filters
          </button>
        </div>
      </div>
      <div className="mt-4">
        <PropertiesForLeaseCardsContainer
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
        />
      </div>
    </div>
  );
}

export default Offices;
