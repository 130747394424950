"use client";
import React from "react";
import { motion } from "framer-motion";
import Partners from "./Partners";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Accreditation } from "./Valuation";

function AboutUs({ pageData }) {
  const videoSource =
    "https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2FLPC%20New%20AVP%202023.mp4?alt=media&token=190a54fe-6f02-4f1a-ae9d-f1e0311260a9";
  return (
    <div>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>About Us | Leechiu Property Consultants </title>
        <meta
          name="description"
          content={
            "Leechiu Property Consultants (LPC) is a privately held professional real estate brokerage services company operating in the Philippines."
          }
        />
      </Helmet> */}
      <div className="text-2xl text-leechiuBlue font-serif font-semibold text-center mt-8">
        About <span className="text-leechiuOrange">Us</span>
      </div>
      <motion.div>
        <video
          className="max-w-[1440px] w-full m-auto"
          src={videoSource}
          autoPlay
          loop
          muted
          playsInline
        ></video>
      </motion.div>
      <div className="max-w-5xl m-auto my-8 p-4">
        {pageData.videoDescription}
      </div>
      <div className="font-serif text-leechiuBlue text-2xl text-center font-semibold">
        Our Partners <span className="text-leechiuOrange">and Affiliates</span>
      </div>
      <div>
        <Partners />
        <Accreditation />
      </div>
      <div
        className="p-4"
        //  style={{
        //   backgroundImage: "url('https://res.cloudinary.com/dtnbpkyvd/image/upload/v1718206867/leechiucomingsoon/o-buildings_57223990_gbwi5b.jpg')",
        //   backgroundSize: "cover",
        //   backgroundPosition: "center",
        // }}
      >
       
      </div>
    </div>
  );
}

export default AboutUs;
