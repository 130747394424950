import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { getDocDataSnap } from "../Firebase.utils/Firebase.utils";

function TenantRep() {
  const [pageData, setPageData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const fetchPageData = async () => {
      const pageDataRef = await getDocDataSnap(
        "website",
        "tenantRep",
        (data) => {
          setPageData(data);
          setIsLoading(false);
        }
      );
    };
    fetchPageData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const cardsDetails = [
    {
      title: "Space Selection",
      description:
        "We help you identify the right space for your business, taking into account factors such as location, size, and budget.",
    },
    {
      title: "Lease Negotiation",
      description:
        "We negotiate with landlords to secure favorable lease terms, including rent, lease duration, and renewal options.",
    },
    {
      title: "Lease Renewal and Restructuring",
      description:
        "We assist in negotiating lease renewals and restructuring agreements to ensure that you get the best deal possible.",
    },
    {
      title: "Market Analysis",
      description:
        "We provide detailed market analysis to help you understand current market trends and make informed decisions. ",
    },
    {
      title: "Transaction Management",
      description:
        "We manage the entire leasing process, including document preparation, to ensure a smooth and efficient transaction.",
    },
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <motion.div className="bg-leechiuBlue h-fit ">
      {/* first section */}

      <Helmet>
        <meta charSet="utf-8" />
        <title>Tenant Representation | Leechiu Property Consultants</title>
        <meta
          name="description"
          content={
            " Our tenant representation specialists facilitate lease acquisitions, renewals and restructuring for their clients. These services are provided together with various tools that help our clients determine the best real estate strategy."
          }
        />
      </Helmet>

      <div
        className="h-[500px]"
        style={{
          backgroundImage:
            'url("https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2FtenantRep%2Fbusinessman-in-formal-clothes-is-with-black-man-ou-2023-11-27-04-54-14-utc.JPG?alt=media&token=1c4d328f-6ee9-48d0-a177-b00c26bbd760")',
          backgroundSize: "cover",
          backgroundPosition: "start",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-leechiuBlue bg-opacity-60 h-full flex items-center justify-center">
          <div className="font-serif text-4xl text-white font-semibold">
            Tenant Representation
          </div>
        </div>
      </div>

      {/* second section */}
      <div className="bg-leechiuBlue text-white p-8">
        <div className="max-w-6xl m-auto space-y-4">
          <div className="font-serif text-4xl font-semibold">
            {pageData.tagline}
            <span className="text-leechiuOrange"></span>
          </div>
          <div className="text-xl">{pageData.pageDescription}</div>
        </div>

        <div className="mt-8 max-w-6xl m-auto space-y-4">
          <div className="text-3xl font-serif font-semibold">
            Our <span className="text-leechiuOrange">Solutions</span>
          </div>
          <div className="text-xl">{pageData.solutionsDescription}</div>
        </div>
      </div>

      {/* third section */}
      <div className="bg-leechiuBlue flex items-center gap-4 p-8 flex-wrap justify-center">
        {pageData.cards.map((card, index) => {
          return (
            <div
              className="h-32
             max-w-[500px] bg-white  rounded-xl text-sm font-sans text-start flex items-center justify-center p-4 gap-1"
            >
              <CheckCircleOutlineIcon className="text-leechiuBlue" />
              <div className="">
                <div className="font-semibold text-leechiuBlue text-xs sm:text-sm">
                  {card.title} : {""}
                  <span className="text-xs sm:text-sm font-normal">
                    {card.description}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* fourth section */}
      <div className=" xl:h-[500px] h-full w-full flex items-center justify-center xl:relative ">
        <div className="hidden xl:block xl:h-[400px] w-full bg-white bg-opacity-60"></div>
        <div className=" xl:absolute top-0 left-0 w-full h-full flex items-center justify-center flex-wrap">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2FtenantRep%2Ftenant%20re-lpc%202.jpg?alt=media&token=261897cb-288c-4a1d-9824-9d249b1bc15b"
            alt=""
            className="h-[500px] w-[500px] object-cover"
          />

          <div className="h-full bg-leechiuBlue text-white flex items-center justify-center ">
            <div className="max-w-[600px] font-normal text-2xl p-8 text-left">
              {pageData.photoCardDescription}

              <div className="border-2 mt-8 w-36 text-xs px-4 py-2 text-center">
                Contact Us
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default TenantRep;
