import React from "react";

export default function LeadersCollage({ leaders }) {
  return (
    <div className="flex items-center flex-wrap">
      {leaders.map((leader, index) => (
        <div key={index}>
          <img
            src={leader.img}
            className="h-[200px] w-[200px] object-cover bg-no-repeat cursor-pointer"
            alt=""
            style={{
              objectPosition: `${leader.objectPosition}`,
              objectFit: `${leader.scale}`,
            }}
          />
        </div>
      ))}
    </div>
  );
}
