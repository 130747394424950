import React from "react";

function ClientsLandlordRep() {
  const clients = [
    {
      name: "Ayala Land",
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/67/Ayala_Land_logo.svg/2560px-Ayala_Land_logo.svg.png",
    },
    {
      name: "Megaworld",
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/25/Megaworld_New_Logo_2019.png/287px-Megaworld_New_Logo_2019.png",
    },
    {
      name: "Federal Land",
      logo: "https://federalland.ph/wp-content/themes/FederalLandTheme/img/logo/fli-logo-header.png",
    },
    {
      name: "Rockwell Land",
      logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTF6DktomgthlqyLVuMI7ML4uYQcblHlB_kHQ&s",
    },
    {
      name: "Cathay Land",
      logo: "https://www.cathaylandinc.com/wp-content/uploads/2021/07/cathayland.png",
    },
  ];

  return (
    <div className="bg-white mt-8 p-8">
      <div className="text-center font-serif text-3xl font-semibold text-leechiuBlue">
        Clients We've Worked With
      </div>
      <div>
        <div className="flex items-center justify-center flex-wrap gap-4">
          {clients.map((client, index) => {
            return (
              <div className="flex items-center justify-center">
                <img src={client.logo} alt={client.name} className="w-36" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ClientsLandlordRep;
