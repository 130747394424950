import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { getDocData } from "../Firebase.utils/Firebase.utils";
import { useNavigate, useParams } from "react-router-dom";
import { motion, useMotionValue, AnimatePresence } from "framer-motion";
import { ref, getDownloadURL, listAll } from "firebase/storage";
import { storage } from "../Firebase.utils/Firebase.utils";
import { SlArrowLeftCircle } from "react-icons/sl";
import { SlArrowRightCircle } from "react-icons/sl";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";

function PropertyDetails() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showModal, setShowModal] = React.useState(false);
  const [property, setProperty] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const id = searchParams.get("id");
  const navigate = useNavigate();

  const [photos, setPhotos] = React.useState([]);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [[page, direction], setPage] = useState([0, 0]);
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  // Track if photos are loading
  const [imagesLoaded, setImagesLoaded] = useState({});
  const [allLoaded, setAllLoaded] = useState(false);

  // Preload image variables
  const [preloadNext, setPreloadNext] = useState(null);
  const [preloadPrev, setPreloadPrev] = useState(null);

  // Add scroll to top effect near other useEffect hooks
  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getProperty = async () => {
      const propertyData = await getDocData("listings", id);
      setProperty(propertyData);
      setIsLoading(false);
    };
    getProperty();
  }, []);

  useEffect(() => {
    const fetchPhotos = async () => {
      const storageRef = ref(storage, `Photos/Listings/${id}`);
      const photoList = await listAll(storageRef);
      const photoUrls = await Promise.all(
        photoList.items.map(async (item) => {
          return await getDownloadURL(item);
        })
      );
      setPhotos(photoUrls);
    };

    fetchPhotos();
  }, [id]);

  useEffect(() => {
    if (containerRef.current) {
      const updateDimensions = () => {
        setDimensions({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight,
        });
      };

      updateDimensions();
      window.addEventListener("resize", updateDimensions);

      return () => window.removeEventListener("resize", updateDimensions);
    }
  }, [containerRef.current]);

  // Track loaded images
  useEffect(() => {
    if (
      photos.length > 0 &&
      Object.keys(imagesLoaded).length === photos.length
    ) {
      setAllLoaded(true);
    }
  }, [imagesLoaded, photos]);

  // Set up preloading for the next and previous images
  useEffect(() => {
    if (photos.length === 0) return;

    const nextIndex =
      currentIndex + 1 < photos.length ? currentIndex + 1 : null;
    const prevIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : null;

    if (nextIndex !== null) setPreloadNext(photos[nextIndex]);
    if (prevIndex !== null) setPreloadPrev(photos[prevIndex]);
  }, [currentIndex, photos]);

  // Handle image load
  const handleImageLoad = (index) => {
    setImagesLoaded((prev) => ({
      ...prev,
      [index]: true,
    }));
  };

  // Calculate the correct page from currentIndex
  useEffect(() => {
    setPage([currentIndex, 0]);
  }, [currentIndex]);

  // Handle breadcrumb navigation
  const handleNavigation = (path) => {
    navigate(path);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-50">
        <div className="flex flex-col items-center">
          <div className="w-16 h-16 border-t-4 border-b-4 border-leechiuOrange rounded-full animate-spin mb-4"></div>
          <p className="font-serif font-bold text-leechiuBlue text-2xl">
            Loading property details...
          </p>
        </div>
      </div>
    );
  }

  const saleFields = [
    {
      title: "Property Name",
      value: property.website_title || property.property_name,
    },
    {
      title: "Address",
      value: property.address,
    },
    {
      title: "Total Asking Price",
      value: property.totalWebsitePrice
        ? property.totalWebsitePrice
        : "Php " +
            parseFloat(property.total_price).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) || "Contact Agent",
    },
    {
      title: "Price per sqm",
      value: property.websitePrice
        ? property.websitePrice
        : "Php " +
          parseFloat(property.selling_price).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
    },
    {
      title: "Size",
      value: parseFloat(property.lot_area).toLocaleString() + " sqms",
    },
    {
      title: "Zoning",
      value: property.zoning,
    },
    {
      title: "Description",
      value: property.listing_description,
    },
  ];

  const leaseFields = [
    {
      title: "Property Name",
      value: property.website_title || property.property_name,
    },
    {
      title: "Address",
      value: property.address,
    },
    {
      title: "Lease Rate per sqm",
      value:
        "Php " +
        parseFloat(property.leaseRate).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
    {
      title: "Leasable Area",
      value: parseFloat(property.lot_area).toLocaleString() + " sqms",
    },
    {
      title: "Description",
      value: property.listing_description,
    },
  ];

  const tableFields =
    property.listing_type === "For Sale" ? saleFields : leaseFields;

  // Framer Motion variants
  const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? dimensions.width : -dimensions.width,
        opacity: 0,
        scale: 0.9,
      };
    },
    center: {
      x: 0,
      opacity: 1,
      scale: 1,
      transition: {
        x: { type: "spring", stiffness: 300, damping: 30 },
        opacity: { duration: 0.2 },
        scale: { duration: 0.2 },
      },
    },
    exit: (direction) => {
      return {
        x: direction < 0 ? dimensions.width : -dimensions.width,
        opacity: 0,
        scale: 0.9,
        transition: {
          x: { type: "spring", stiffness: 300, damping: 30 },
          opacity: { duration: 0.2 },
          scale: { duration: 0.2 },
        },
      };
    },
  };

  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };

  const paginate = (newDirection) => {
    // Make sure we don't go out of bounds
    const nextIndex = currentIndex + newDirection;
    if (nextIndex < 0 || nextIndex >= photos.length) return;

    setCurrentIndex(nextIndex);
    setPage([nextIndex, newDirection]);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white py-8 text-left relative z-0">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {property?.website_title || property.property_name} | Leechiu Property
          Consultants
        </title>
        <meta
          name="description"
          content={
            "Looking to buy property in the Philippines? Browse Leechiu's diverse portfolio of properties for sale, including residential, commercial, and industrial listings nationwide."
          }
        />
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-0">
        {/* Breadcrumb */}
        <div className="mb-8 text-left">
          <div className="flex items-center text-sm text-gray-500">
            <span
              className="cursor-pointer hover:text-leechiuBlue transition-colors duration-200"
              onClick={() => handleNavigation("/")}
            >
              Home
            </span>
            <span className="mx-2">/</span>
            <span
              className="cursor-pointer hover:text-leechiuBlue transition-colors duration-200"
              onClick={() =>
                handleNavigation(
                  "/property-search?propertyType=all&location=all&search=&listingType=For%20Sale"
                )
              }
            >
              Properties
            </span>
            <span className="mx-2">/</span>
            <span className="text-leechiuBlue font-medium">
              {property.website_title || property.property_name}
            </span>
          </div>
        </div>

        {/* Property Type Tag */}
        <div className="mb-5">
          <span className="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-leechiuBlue bg-opacity-10 text-leechiuBlue">
            {property.listing_type}
          </span>
        </div>

        {/* Title Section */}
        <div className="text-left mb-8">
          <h1 className="font-bold text-gray-900 text-3xl mb-2">
            {property.website_title || property.property_name}
          </h1>
          <div className="flex items-center gap-3">
            <div className="h-1 w-12 bg-leechiuOrange"></div>
            <p className="text-gray-600 text-lg">{property.address}</p>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row gap-10">
          {/* Left Column - Photos */}
          <div className="w-full lg:w-7/12 relative z-0">
            {/* Enhanced Photo Carousel */}
            <div
              className="relative rounded-xl overflow-hidden shadow-[0_5px_30px_rgba(0,0,0,0.08)] bg-white h-[450px] z-0"
              ref={containerRef}
            >
              {/* Preload images for better performance */}
              {preloadNext && (
                <link rel="preload" as="image" href={preloadNext} />
              )}
              {preloadPrev && (
                <link rel="preload" as="image" href={preloadPrev} />
              )}

              {/* Main carousel */}
              <div className="relative w-full h-full z-0">
                {!allLoaded && photos.length > 0 && (
                  <div className="absolute inset-0 flex items-center justify-center bg-gray-100 z-10">
                    <div className="w-16 h-16 border-t-4 border-b-4 border-leechiuOrange rounded-full animate-spin"></div>
                  </div>
                )}

                <AnimatePresence
                  initial={false}
                  custom={direction}
                  mode="popLayout"
                >
                  {photos.length > 0 && (
                    <motion.div
                      key={page}
                      custom={direction}
                      variants={variants}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      transition={{
                        x: { type: "spring", stiffness: 300, damping: 30 },
                        opacity: { duration: 0.2 },
                      }}
                      className="absolute w-full h-full z-10"
                      drag="x"
                      dragConstraints={{ left: 0, right: 0 }}
                      dragElastic={1}
                      onDragEnd={(e, { offset, velocity }) => {
                        const swipe = swipePower(offset.x, velocity.x);

                        if (swipe < -swipeConfidenceThreshold) {
                          paginate(1);
                        } else if (swipe > swipeConfidenceThreshold) {
                          paginate(-1);
                        }
                      }}
                    >
                      <img
                        src={photos[currentIndex]}
                        alt={`Property photo ${currentIndex + 1}`}
                        className="object-cover w-full h-full"
                        onLoad={() => handleImageLoad(currentIndex)}
                        draggable={false}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>

                {/* Photo counter */}
                <div className="absolute top-4 right-4 bg-black bg-opacity-50 text-white text-sm px-3 py-1 rounded-full z-20">
                  {currentIndex + 1} / {photos.length}
                </div>

                {/* Navigation buttons */}
                {photos.length > 1 && (
                  <>
                    <motion.button
                      className={`absolute top-1/2 left-4 transform -translate-y-1/2 bg-white shadow-lg rounded-full p-2 z-20 ${
                        currentIndex === 0
                          ? "opacity-40 cursor-not-allowed"
                          : "opacity-80 hover:opacity-100"
                      }`}
                      onClick={() => paginate(-1)}
                      disabled={currentIndex === 0}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <SlArrowLeftCircle className="text-leechiuBlue h-8 w-8" />
                    </motion.button>
                    <motion.button
                      className={`absolute top-1/2 right-4 transform -translate-y-1/2 bg-white shadow-lg rounded-full p-2 z-20 ${
                        currentIndex === photos.length - 1
                          ? "opacity-40 cursor-not-allowed"
                          : "opacity-80 hover:opacity-100"
                      }`}
                      onClick={() => paginate(1)}
                      disabled={currentIndex === photos.length - 1}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <SlArrowRightCircle className="text-leechiuBlue h-8 w-8" />
                    </motion.button>
                  </>
                )}
              </div>
            </div>

            {/* Thumbnails */}
            {photos.length > 1 && (
              <div className="mt-4 overflow-hidden">
                <motion.div
                  className="flex gap-3 pb-2"
                  drag="x"
                  dragConstraints={{
                    right: 0,
                    left: -(photos.length * 88 - dimensions.width + 16), // Calculate drag constraints
                  }}
                >
                  {photos.map((photo, index) => (
                    <motion.div
                      key={index}
                      className={`h-20 w-20 flex-shrink-0 cursor-pointer rounded-lg overflow-hidden ${
                        currentIndex === index
                          ? "ring-2 ring-leechiuOrange shadow-md"
                          : "ring-1 ring-gray-200 opacity-70"
                      }`}
                      onClick={() => setCurrentIndex(index)}
                      whileHover={{
                        scale: 1.05,
                        opacity: 1,
                        transition: { duration: 0.2 },
                      }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <img
                        src={photo}
                        alt={`thumbnail ${index}`}
                        className="h-full w-full object-cover"
                        loading="lazy"
                        onLoad={() => handleImageLoad(`thumb-${index}`)}
                      />
                    </motion.div>
                  ))}
                </motion.div>
              </div>
            )}

            {/* Description section */}
            {property.listing_description && (
              <div className="mt-8 bg-white rounded-xl shadow-[0_5px_30px_rgba(0,0,0,0.08)] p-6">
                <h3 className="uppercase font-semibold text-leechiuBlue text-lg mb-4 flex items-center">
                  <div className="w-1 h-5 bg-leechiuOrange mr-2"></div>
                  Description
                </h3>
                <div className="prose max-w-none text-gray-700 leading-relaxed">
                  {property.listing_description}
                </div>
              </div>
            )}

            {/* Google Maps section */}
            <div className="mt-8 bg-white rounded-xl shadow-[0_5px_30px_rgba(0,0,0,0.08)] overflow-hidden">
              <div className="p-6 pb-3">
                <h3 className="uppercase font-semibold text-leechiuBlue text-lg mb-2 flex items-center">
                  <div className="w-1 h-5 bg-leechiuOrange mr-2"></div>
                  Location
                </h3>
                <p className="text-gray-600 mb-4">{property.address}</p>
              </div>
              <div className="w-full h-[400px] relative">
                {property.address && (
                  <iframe
                    title="Property Location Map"
                    className="w-full h-full border-0"
                    src={`https://maps.google.com/maps?q=${encodeURIComponent(
                      property.address
                    )}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                )}
              </div>
            </div>
          </div>

          {/* Right Column - Details */}
          <div className="w-full lg:w-5/12 z-0">
            <div className="bg-white rounded-xl shadow-[0_5px_30px_rgba(0,0,0,0.08)] overflow-hidden sticky top-24 z-0">
              {/* Price highlight */}
              <div className="bg-gradient-to-r from-leechiuBlue to-[#0a4d8c] text-white p-6">
                <div className="flex items-baseline justify-between">
                  <h2 className="text-2xl font-bold">
                    {property.listing_type === "For Sale"
                      ? property.totalWebsitePrice ||
                        `Php ${parseFloat(property.total_price).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}`
                      : `Php ${parseFloat(property.leaseRate).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )} /sqm`}
                  </h2>
                  <span className="text-sm bg-white bg-opacity-20 rounded-full px-3 py-1">
                    {property.listing_type}
                  </span>
                </div>
                <p className="text-sm text-white text-opacity-80 mt-1">
                  {property.listing_type === "For Sale"
                    ? `${
                        property.websitePrice ||
                        `Php ${parseFloat(
                          property.selling_price
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`
                      } per sqm`
                    : "Monthly rate"}
                </p>
              </div>

              {/* Property highlights */}
              <div className="p-6 border-b border-gray-100">
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex flex-col p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
                    <span className="text-sm text-gray-500 mb-1">Area</span>
                    <span className="font-bold text-leechiuBlue text-lg">
                      {parseFloat(property.lot_area).toLocaleString()} sqm
                    </span>
                  </div>
                  <div className="flex flex-col p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
                    <span className="text-sm text-gray-500 mb-1">Zoning</span>
                    <span className="font-bold text-leechiuBlue text-lg">
                      {property.zoning || "N/A"}
                    </span>
                  </div>
                </div>
              </div>

              {/* Property details */}
              <div className="p-6">
                <h3 className="uppercase font-semibold text-leechiuBlue text-lg mb-4 flex items-center">
                  <div className="w-1 h-5 bg-leechiuOrange mr-2"></div>
                  Property Details
                </h3>
                <div className="space-y-4">
                  {tableFields
                    .filter(
                      (field) =>
                        field.title !== "Size" &&
                        field.title !== "Zoning" &&
                        field.title !== "Description"
                    )
                    .map(
                      (field, index) =>
                        field.value && (
                          <div
                            key={index}
                            className="flex flex-col border-b border-gray-100 pb-3 last:border-0"
                          >
                            <span className="text-sm text-gray-500">
                              {field.title}
                            </span>
                            <span className="text-gray-800 font-medium">
                              {field.value}
                            </span>
                          </div>
                        )
                    )}
                </div>

                {/* CTA Section */}
                <div className="mt-8">
                  <motion.button
                    className="bg-leechiuOrange text-white py-4 px-6 rounded-lg w-full hover:bg-[#ff6b2a] transition-colors font-medium shadow-lg shadow-orange-200 flex items-center justify-center"
                    onClick={() => setShowModal(true)}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 mr-2"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                    </svg>
                    Inquire About This Property
                  </motion.button>
                </div>

                {/* Contact Info */}
                <div className="mt-6 p-4 rounded-lg bg-gray-50 text-center">
                  <p className="text-sm text-gray-600 mb-1">
                    Have questions about this property?
                  </p>
                  <p className="text-leechiuBlue font-medium">
                    Contact us at (02) 8888 8111
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal - higher z-index to appear above navbar */}
      <AnimatePresence>
        {showModal && (
          <Modal setShowModal={setShowModal} property={property} id={id} />
        )}
      </AnimatePresence>
    </div>
  );
}

const InputBox = ({ label, type, name, value, onChange, required }) => {
  return (
    <div className="flex flex-col mb-4">
      <label
        htmlFor={name}
        className="text-sm text-white mb-1 flex items-center"
      >
        {label} {required && <span className="text-red-300 ml-1">*</span>}
      </label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        className="border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-leechiuOrange"
        required={required}
        placeholder={`Enter your ${label.toLowerCase()}`}
      />
    </div>
  );
};

const Modal = ({ property, setShowModal, id }) => {
  const [formData, setFormData] = React.useState({});
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const dataObject = {
      ...formData,
      team_members: property.team_members,
      id: id,
      flyer: property.files?.Flyer,
      web_title: property.website_title || property.property_name,
      weblink: `${window.location.href}`,
    };

    try {
      const res = await fetch(
        "https://leechiu-app-email.vercel.app/api/forsale",
        {
          method: "POST",
          body: JSON.stringify(dataObject),
        }
      );

      navigate("/thankyou");
    } catch (error) {
      console.error("Error submitting form:", error);
      setIsSubmitting(false);
    }
  };

  return (
    <motion.div
      className="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm flex items-center justify-center z-40 p-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        initial={{ opacity: 0, y: 50, scale: 0.95 }}
        animate={{ opacity: 1, y: 0, scale: 1 }}
        exit={{ opacity: 0, y: 50, scale: 0.95 }}
        transition={{ type: "spring", damping: 25 }}
        className="max-w-md w-full"
      >
        <form
          onSubmit={handleSubmit}
          className="bg-gradient-to-b from-leechiuBlue to-[#0a4d8c] rounded-xl overflow-hidden shadow-2xl"
        >
          <div className="p-6">
            <div className="flex justify-between items-center mb-6">
              <h1 className="text-white text-xl font-bold">
                Inquire About This Property
              </h1>
              <button
                type="button"
                onClick={() => setShowModal(false)}
                className="text-white opacity-70 hover:opacity-100 bg-white bg-opacity-10 rounded-full p-1 transition-all hover:bg-opacity-20"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>

            <div className="bg-white bg-opacity-10 rounded-lg p-3 mb-6">
              <p className="text-white text-sm">
                Interested in{" "}
                <span className="font-medium">
                  {property.website_title || property.property_name}
                </span>
                ? Complete this form and our team will contact you shortly.
              </p>
            </div>

            <InputBox
              label="Full Name"
              type="text"
              name="name"
              onChange={handleChange}
              required={true}
            />
            <InputBox
              label="Email"
              type="email"
              name="email"
              onChange={handleChange}
              required={true}
            />
            <InputBox
              label="Contact Number"
              type="text"
              name="contact"
              onChange={handleChange}
              required={true}
            />

            <div className="flex flex-col mb-4">
              <label htmlFor="message" className="text-sm text-white mb-1">
                Message
              </label>
              <textarea
                name="message"
                rows="3"
                onChange={handleChange}
                className="border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-leechiuOrange"
                placeholder="Tell us what you want to know about this property"
              ></textarea>
            </div>
          </div>

          <div className="bg-white bg-opacity-5 px-6 py-4 flex items-center justify-end gap-3">
            <button
              className="bg-white bg-opacity-20 text-white px-4 py-2 rounded-lg hover:bg-opacity-30 transition-colors"
              onClick={() => setShowModal(false)}
              type="button"
            >
              Cancel
            </button>
            <button
              className={`bg-leechiuOrange text-white px-6 py-2 rounded-lg hover:bg-opacity-90 transition-colors flex items-center ${
                isSubmitting ? "opacity-70 cursor-not-allowed" : ""
              }`}
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Sending...
                </>
              ) : (
                "Send Inquiry"
              )}
            </button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};

export default PropertyDetails;
