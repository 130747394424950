import React from "react";
import { useSearchParams } from "react-router-dom";

function ThankYou() {
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("email");

  return (
    <div className="text-4xl py-8 h-[350px] flex flex-col items-center justify-center bg-leechiuBlue text-white">
      <div>Thank you for your inquiry</div>

      <div className="text-lg font-serif text-leechiuOrange">
        Please check your email for our response
      </div>
      <div className="text-xs text-center">{email && <div>Email Provided: {email}</div>}</div>
    </div>
  );
}

export default ThankYou;
