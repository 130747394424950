import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClientTestimonials from "./ClientTestimonials";
import ClientsLandlordRep from "./ClientsLandlordRep";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { getDocDataSnap } from "../Firebase.utils/Firebase.utils";

function Hotels() {
  const [pageData, setPageData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const fetchPageData = async () => {
      const pageDataRef = await getDocDataSnap(
        "website",
        "hotelsTourism",
        (data) => {
          setPageData(data);
          setIsLoading(false);
        }
      );
    };
    fetchPageData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const bgImg =
    "https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2Fhospitality%2Fel-nido-palawan-philippines-sandy-beach-with-pa-2023-11-27-05-09-22-utc.jpg?alt=media&token=e6327c47-bd47-470f-821e-ba9691b40ce2";

  if (isLoading) {
    return <div>Loading...</div>;
  }

  console.log(pageData);

  return (
    <div className="bg-leechiuBlue">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hotel, Tourism, and Leisure | LPC</title>
        <meta
          name="description"
          content={
            "Maximize the potential of your hospitality investment with LPC's specialized expertise in hotels, resorts, and leisure properties. Discover opportunities in the booming Philippine tourism sector."
          }
        />
      </Helmet>
      <div
        className="h-[500px] w-full"
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-leechiuBlue h-full w-full bg-opacity-60 text-white flex items-center justify-center">
          <div className="font-serif text-4xl font-semibold">
            Hotels, Tourism and Leisure
          </div>
        </div>
      </div>
      <div className="h-12 w-full" />
      <FirstDescription pageData={pageData} />
      {/* <Accreditation /> */}
      <div className="h-12 w-full" />
      <SolutionsCard pageData={pageData} />
      <DescriptionBand pageData={pageData} />
      <ClientTestimonials pageData={pageData} />
      <ClientsLandlordRep pageData={pageData} />
    </div>
  );
}

const FirstDescription = ({ pageData }) => {
  return (
    <div className=" flex items-center justify-center flex-wrap bg-leechiuBlue">
      <div className="max-w-[800px]">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2Fhospitality%2Fel-nido-palawan-philippines-tropical-scenery-of-2023-11-27-05-36-20-utc.jpg?alt=media&token=8b6a902e-348d-46a3-8f08-bbae6ac9ca37"
          alt=""
          className="h-[400px] w-[500px] object-cover"
          style={{
            maximumWidth: "800px",
            objectFit: "cover",
          }}
        />
      </div>
      <div className="w-[600px] bg-white text-left px-16 py-8">
        <div className="font-semibold">
          <div className="font-serif text-4xl text-leechiuBlue">
            {pageData.tagline}
          </div>
        </div>
        <div className="text-leechiuBlue mt-8">{pageData.pageDescription}</div>
      </div>
    </div>
  );
};

const Accreditation = () => {
  const accreditedAgencies = [
    {
      name: "Bangko Sentral ng Pilipinas",
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Bangko_Sentral_ng_Pilipinas_2020_logo.png/800px-Bangko_Sentral_ng_Pilipinas_2020_logo.png",
    },
    {
      name: "Securities and Exchange Commission",
      logo: "https://www.sec.gov.ph/wp-content/uploads/2019/12/LogoNew-300x185.png",
    },
    {
      name: "Philippine Stock Exchange",
      logo: "https://cdn-asset.aseanexchanges.org/wp-content/uploads/2023/06/PSE-Logo.png",
    },
  ];

  return (
    <div className="text-leechiuBlue mt-12 bg-white p-8">
      <div className="font-serif text-4xl font-semibold text-center ">
        Accreditations
      </div>
      <div className="flex items-end justify-center gap-8 mt-8">
        {accreditedAgencies.map((agency) => (
          <div className="flex items-center justify-center flex-col">
            <img src={agency.logo} alt={agency.name} className="h-20" />
            <div>{agency.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

const DescriptionBand = ({ pageData }) => {
  return (
    <>
      <div className=" xl:h-[500px] h-full w-full flex items-center justify-center xl:relative">
        <div className="hidden xl:block xl:h-[400px] w-full bg-white bg-opacity-60"></div>
        <div className=" xl:absolute top-0 left-0 w-full h-full flex items-center justify-center flex-wrap">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2Fhospitality%2Fpalawan-philippines-island-hopping-trip-in-el-ni-2023-11-27-05-36-03-utc.jpg?alt=media&token=30470c88-81b0-4634-8556-723cc0322d67"
            alt=""
            className="h-[500px] w-[500px] object-cover"
          />
          <div className="h-full bg-leechiuBlue text-white flex items-center justify-center ">
            <div className="max-w-[600px] font-normal text-lg p-8 text-left">
              {pageData.photoCardDescription}

              <div className="border-2 mt-8 w-36 text-xs px-4 py-2 text-center">
                <Link to="/contactUs">Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const SolutionsCard = ({ pageData }) => {
  return (
    <div>
      <div className="text-white mt-8">
        <div className="font-serif text-4xl font-semibold">
          {"Our "}
          <span className="text-leechiuOrange">Solutions</span>
        </div>
        <div className="max-w-6xl m-auto mt-4">
          {pageData.solutionsDescription}
        </div>
      </div>
      <div className="flex items-center gap-4 p-8 flex-wrap justify-center">
        {pageData.cards.map((card, index) => {
          return (
            <div
              className="h-32
          max-w-[500px] bg-white  rounded-xl text-sm font-sans text-start flex items-center justify-center p-4 gap-1"
              key={index}
            >
              <CheckCircleOutlineIcon />
              <div className="text-leechiuBlue">
                <div className="font-semibold text-leechiuBlue text-xs sm:text-sm">
                  {card.title} : {""}
                  <span className="text-xs sm:text-sm font-normal">
                    {card.description}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Hotels;
