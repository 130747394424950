import React from "react";
import { Link } from "react-router-dom";

export default function AnnouncementPSE() {
  return (
    <div
      className="text-white flex items-center justify-between h-[400px]"
      style={{
        backgroundImage: `url(
          https://firebasestorage.googleapis.com/v0/b/database-project-32188.appspot.com/o/website%2Fpseaccreditation.jpeg?alt=media&token=ba3695f3-7d19-41b5-a822-0c8b0b9d4af7
        )`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="h-full w-full bg-white bg-opacity-70">
        <div className="flex items-center justify-between h-full w-full text-leechiuBlue p-8 flex-wrap">
          <div>
            <div className="bg-leechiuOrange rounded-lg px-2 py-1 w-28 uppercase text-sm mb-4 text-white">
              News
            </div>
            <div className="text-3xl font-bold font-serif text-left">
              Leechiu Property Consultants is Now Accredited by PSE for Property
              Valuation
            </div>
          </div>
          <div className="font-bold  mt-12 px-2 py-1 cursor-pointer hover:underline hover:text-leechiuOrange">
            <Link to="/news/lpc-is-now-pse-accredited">Read more</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
